import { Ads } from '@/lib/constants/constants';

export function RequestAds() {
    window.adn = window.adn || {};
    adn.calls = adn.calls || [];

    var adUnits = Ads.map((x) => ({
        auId: x.tagId
    }));

    adn.calls.push(function () {
        adn.request({
            network: '23175d',
            load: 'lazy',
            adUnits: adUnits
        });
    });
}
